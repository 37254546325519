import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddProduct from './product/AddProduct.js';
import AddCategory from './category/AddCategory.js';
import AddSubCategory from './sub-category/AddSubCategory.js';
import {
    faBoxOpen,
    faAnglesDown,
    faLayerGroup,
    faCopyright, faTicketSimple, faUser, faBoxesPacking, faCartShopping, faFileCirclePlus

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {CategoryTable} from "./category/CategoryTable";
import {SubCategoryTable} from "./sub-category/SubCategoryTable";
import {ProductTable} from "./product/ProductTable";
import {BrandTable} from "./brand/BrandTable";
import AddBrand from "./brand/AddBrand";
import {Link, useParams} from "react-router-dom";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch} from "react-redux";
import {subDomainUrl} from "../../hooks/config";
import {ProductDataTable} from "./product/ProductDataTable";
import {DiscountTable} from "./discount/DiscountTable";
import AddDiscount from "./discount/AddDiscount";
import OrderDataTable from "./order/OrderDataTable";
import OfflineOrderTable from "./offline-order/OfflineOrderTable";
import {makeApiCall} from "../../hooks/api/makeApiCall";
import {
    PACKAGING_ITEMS_DROPDOWN_LIST_DATA,
    PRODUCT_DROPDOWN_LIST_DATA,
    VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA
} from "../../store/constant/inventoryConstant";
import GemOrderTable from "./offline-order/GemOrderTable";
import TransporterTable from "./transporter/TransporterTable";
import TransporterForm from "./transporter/TransporterForm";
import {
    actionToGetCityListApiCall,
    actionToGetPinCodeListApiCall,
    actionToGetStateListApiCall
} from "../../store/action";
import CreatePurchaseOrderForm from "./purchase/CreatePurchaseOrderForm";
import PurchaseDataTable from "./purchase/PurchaseDataTable";
import SchoolUcTable from "./school-uc/SchoolUcTable";
import AddSchoolUcDataModal from "./school-uc/AddSchoolUcDataModal";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {SCHOOL_LIST, SCHOOL_TYPE_LIST} from "../../store/constant/salesConstant";
import {DESIGNATION_LIST} from "../../store/constant";


function InventoryComponent() {
    let {tabName} = useParams();
    const [addOpen, setAddOpen] = useState(false);
    const [isAddProductOpen, setIsAddProductOpen] = useState(false);
    const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
    const [isAddSubCategoryOpen, setIsAddSubCategoryOpen] = useState(false);
    const [isAddSchoolUcOpen, setIsAddSchoolUcOpen] = useState(false);
    const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('inventory');
    const [fetchDataTable, setFetchDataTable] = useState(false);
    const dispatch = useDispatch();

    const getTabTable =() =>{
        switch (activeTab){
            case 'discount':
                return <DiscountTable />;
            case 'category':
                return <CategoryTable />;
            case 'sub-category':
                return <SubCategoryTable />;
            case 'product':
                return <ProductTable />;
            case 'product-datatable':
                return <ProductDataTable />;
            case 'brand':
                return <BrandTable />;
            case 'order':
                return <OrderDataTable  fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'transporter':
                return <TransporterTable key="transporter" fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable} type={"transporter"} />;
            case 'vendor':
                return <TransporterTable key="vendor" fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable} type={"vendor"} />;
            case 'pre-order':
                return <OfflineOrderTable  fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'gem-order':
                return <GemOrderTable  fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'purchase':
                return <PurchaseDataTable  fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'school_uc':
                return <SchoolUcTable  fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            default:
                return <ProductTable />;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
        setFetchDataTable((prev) => !prev); // Trigger re-fetch when tab changes
    }, [tabName]);
    useEffectOnce(()=>{
        //dispatch(actionToGetCompanyListApiCall({in_inventory_use:1}));
        dispatch(actionToGetStateListApiCall());
        dispatch(actionToGetCityListApiCall());
        dispatch(actionToGetPinCodeListApiCall());
        makeApiCall(`inventory/get-product-dropdown-list`, PRODUCT_DROPDOWN_LIST_DATA, dispatch);
        makeApiCall(`inventory/get-vendor-transporter-dropdown`,VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA,dispatch)
        makeApiCall(`inventory/get-packaging-dropdown`,PACKAGING_ITEMS_DROPDOWN_LIST_DATA,dispatch);
        makeApiCall(`sales/get-school-list-dropdown`,SCHOOL_LIST,dispatch);
        makeApiCall(`common/get-designation-list`,DESIGNATION_LIST,dispatch);
        makeApiCall(`sales/get-school-type`,SCHOOL_TYPE_LIST,dispatch);
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/inventory/product-datatable'} className='tab'><button
                                    className={`tab ${activeTab === 'product-datatable' ? 'active' : ''}`} >
                                    Product Datatable</button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/category'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'category' ? 'active' : ''}`}>
                                    Category
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/sub-category'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'sub-category' ? 'active' : ''}`}>
                                    Sub-Category
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/brand'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'brand' ? 'active' : ''}`}>
                                    Brand
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/discount'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'discount' ? 'active' : ''}`}>
                                    Discount
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/transporter'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'transporter' ? 'active' : ''}`}>
                                    Transporter
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/vendor'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'vendor' ? 'active' : ''}`}>
                                    Vendor
                                </button>
                                </Link>
                               {/* <Link to={subDomainUrl+'/inventory/packaging-item'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'packaging-item' ? 'active' : ''}`}>
                                    Packaging Item
                                </button>
                                </Link>*/}
                                <Link to={subDomainUrl+'/inventory/gem-order'} className='tab'>
                                    <button
                                        className={`tab ${activeTab === 'gem-order' ? 'active' : ''}`}>
                                        Gem Order
                                    </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/pre-order'} className='tab'>
                                    <button
                                        className={`tab ${activeTab === 'pre-order' ? 'active' : ''}`}>
                                        Pre Order
                                    </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/order'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'order' ? 'active' : ''}`}>
                                    Order
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/purchase'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'purchase' ? 'active' : ''}`}>
                                    Purchase
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/inventory/school_uc'} className='tab'>
                                <button
                                    className={`tab ${activeTab === 'school_uc' ? 'active' : ''}`}>
                                    UC Data
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='sub-header_box'>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Product'>
                                <button className='common-button' onClick={() => setIsAddProductOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faBoxOpen} />
                                </button>
                            </Tippy>
                            <AddProduct isOpen={isAddProductOpen} onRequestClose={() => setIsAddProductOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Category'>
                                <button  className='common-button' onClick={() => setIsAddCategoryOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faAnglesDown} />
                                </button>
                            </Tippy>
                            <AddCategory isOpen={isAddCategoryOpen} onRequestClose={() => setIsAddCategoryOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Sub-Category'>
                                <button  className='common-button' onClick={() => setIsAddSubCategoryOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faLayerGroup} />
                                </button>
                            </Tippy>
                            <AddSubCategory isOpen={isAddSubCategoryOpen} onRequestClose={() => setIsAddSubCategoryOpen(false)} />
                        </div>

                        <div className='subheader-components'>
                            <Tippy content='Add Brand'>
                                <button  className='common-button' onClick={() => setIsAddBrandOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faCopyright} />
                                </button>
                            </Tippy>
                            <AddBrand isOpen={isAddBrandOpen} onRequestClose={() => setIsAddBrandOpen(false)} />
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Discount Coupon'>
                                <button  className='common-button' onClick={() => setAddOpen('add_discount')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faTicketSimple} />
                                </button>
                            </Tippy>
                            <AddDiscount isOpen={addOpen === 'add_discount'} onRequestClose={() => setAddOpen(false)} />
                        </div>
                        <div className='subheader-components'>
                            <Tippy content='Add Transporter'>
                                <button  className='common-button' onClick={() => setAddOpen('add_transporter')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUser} />
                                </button>
                            </Tippy>

                            {addOpen === 'add_transporter' && (
                                <TransporterForm
                                    type={"transporter"}
                                    handleExternalFetch={setFetchDataTable}
                                    isOpen={addOpen==='add_transporter'}
                                    onRequestClose={() => setAddOpen(false)}
                                />
                            )}
                        </div>
                        <div className='subheader-components'>
                            <Tippy content='Add Vendor'>
                                <button  className='common-button' onClick={() => setAddOpen('add_vendor')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUser} />
                                </button>
                            </Tippy>

                            {addOpen === 'add_vendor' && (
                                <TransporterForm
                                    type={"vendor"}
                                    handleExternalFetch={setFetchDataTable}
                                    isOpen={addOpen==='add_vendor'}
                                    onRequestClose={() => setAddOpen(false)}
                                />
                            )}
                        </div>
                        <div className='subheader-components'>
                            <Tippy content='Add Purchase'>
                                <button  className='common-button' onClick={() => setAddOpen('add_purchase')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faCartShopping} />
                                </button>
                            </Tippy>

                            {addOpen === 'add_purchase' && (
                                <CreatePurchaseOrderForm
                                    handleExternalFetch={setFetchDataTable}
                                    isOpen={addOpen==='add_purchase'}
                                    onRequestClose={() => setAddOpen(false)}
                                />
                            )}
                        </div>
                        <div className='subheader-components'>
                            <Tippy content='Add School UC'>
                                <button  className='common-button' onClick={() => setIsAddSchoolUcOpen(true)}>
                                    <FontAwesomeIcon className='filter-icon' icon={faFileCirclePlus} />
                                </button>
                            </Tippy>
                            <AddSchoolUcDataModal isOpen={isAddSchoolUcOpen} onRequestClose={() => setIsAddSchoolUcOpen(false)} />
                        </div>
                    </div>
                    </div>
                </div>

                <div className='table-container'>
                    <div className="tab-content">
                        {getTabTable()}
                    </div>
                </div>
            </section>
        </>
    )
}

export default InventoryComponent;
